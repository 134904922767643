import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import axiosInstance from './axiosInstance';
import { AuthContext } from './AuthContext';
import useAxios from './axiosInstance';



const AddTransaction = () => {
    const axiosInstance = useAxios();
  const { id } = useParams(); // Portfolio ID
  const navigate = useNavigate();
//   const [stocks, setStocks] = useState([]);
  const [filteredStocks, setFilteredStocks] = useState([]);
  const [transaction, setTransaction] = useState({
    stock_id: '',
    transaction_type: 'buy',
    quantity: '',
    price: '',
    timestamp: new Date().toISOString().slice(0, 16), // Default to current date and time
    main_portfolio_id: id // Add main_portfolio_id to transaction state
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const [selectedStock, setSelectedStock] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state
  const { authState } = useContext(AuthContext);


  useEffect(() => {
    const fetchStocks = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get('api/stocks', {
            params: { query: searchQuery },
        });
        setFilteredStocks(response.data);
      } catch (error) {
        setError('Failed to fetch stocks.');
        console.error("There was an error fetching the stocks!", error);
      } finally {
        setLoading(false);
      }
    };

    if (searchQuery) {
      fetchStocks();
    } else {
      setFilteredStocks([]);
    }
    // eslint-disable-next-line
  }, [searchQuery, authState.token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axiosInstance.post(`api/transactions`, {
        ...transaction,
        timestamp: new Date(transaction.timestamp).toISOString()
      });
      navigate(`/portfolio/${id}`);
    } catch (error) {
      setError(error.response.data.error || 'Failed to add transaction');
      console.error("There was an error adding the transaction!", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBackToDashboard = () => {
    navigate('/dashboard'); // Navigate to the home page
  };

  return (
    <div className="p-6 relative">
        {/* Flex container for buttons */}
        <div className="flex justify-between items-center mb-6">
        <button 
            onClick={handleBackToDashboard} 
            className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 transition"
        >
            Back to Dashboard
        </button>
        {/* <button 
            onClick={handleAddTransaction} 
            className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-600 transition"
        >
            Add Transaction
        </button> */}
        </div>
      <h1 className="text-2xl font-bold mb-4">Add Transaction</h1>
      {loading && (
        <div className="flex justify-center items-center mb-4">
          <div className="animate-spin border-t-2 border-blue-500 rounded-full w-10 h-10 border-b-2"></div>
        </div>
      )}
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2">Search Stock</label>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border p-2 w-full"
            placeholder="Search by name or symbol"
          />
          {searchQuery && filteredStocks.length > 0 && (
            <ul className="mt-2 border border-gray-300 rounded-lg bg-white shadow-lg">
              {filteredStocks.map(stock => (
                <li
                  key={stock.id}
                  onClick={() => {
                    setTransaction(prev => ({
                      ...prev,
                      stock_id: stock.id,
                      main_portfolio_id: id
                    }));
                    setSelectedStock(stock);
                    setSearchQuery(''); // Clear search query and close dropdown
                    setFilteredStocks([]);
                  }}
                  className="cursor-pointer p-2 hover:bg-gray-200"
                >
                  {stock.name} ({stock.symbol})
                </li>
              ))}
            </ul>
          )}
        </div>

        {selectedStock && (
          <div className="mb-4">
            <label className="block mb-2">Selected Stock</label>
            <div className="border p-2 bg-gray-100">
              <span className="font-semibold">{selectedStock.name} ({selectedStock.symbol})</span>
            </div>
          </div>
        )}

        <div className="mb-4">
          <label className="block mb-2">Transaction Type</label>
          <select
            value={transaction.transaction_type}
            onChange={(e) => setTransaction({ ...transaction, transaction_type: e.target.value })}
            className="border p-2 w-full"
            required
          >
            <option value="buy">Buy</option>
            <option value="sell">Sell</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Quantity</label>
          <input
            type="number"
            value={transaction.quantity}
            onChange={(e) => setTransaction({ ...transaction, quantity: e.target.value })}
            className="border p-2 w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Price</label>
          <input
            type="number"
            step="0.01"
            value={transaction.price}
            onChange={(e) => setTransaction({ ...transaction, price: e.target.value })}
            className="border p-2 w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Timestamp</label>
          <input
            type="datetime-local"
            value={transaction.timestamp}
            onChange={(e) => setTransaction({ ...transaction, timestamp: e.target.value })}
            className="border p-2 w-full"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-green-500 text-white py-2 px-4 rounded"
          disabled={loading}
        >
          {loading ? 'Adding...' : 'Add Transaction'}
        </button>
      </form>
    </div>
  );
};

export default AddTransaction;
