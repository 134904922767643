import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import ResponsiveAppBar from './ResponsiveAppBar';
import StockList from './StockList';
import MarketIndexes from './MarketIndexes';
import LogIn from './LogIn';
import SignUp from './SignUp';
import { AuthProvider } from './AuthContext';
import CreatePortfolio from './CreatePortfolio';
import PortfolioDetails from './PortfolioDetails';
import DashBoard from './Dashboard';
// import MainPortfolioList from './MainPortfolioList';
import AddTransaction from './AddTransaction';
import PrivateRoute from './PrivateRoute';
import TransactionHistory from './TransactionHistory'


const LazyStockDetails = lazy(() => import('./StockDetails'));
const LazyAccount = lazy(() => import('./Account'));

function App() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [totalCount, setTotalCount] = useState(0);
  const [queryParam, setQueryParam] = useState('');
  const [filteredData, setfilteredData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handlePageChange(event, newPage) {
    setPage(newPage);
  }

  // Simulate fetching data from an API
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        var varPage = page + 1;
        const flaskApiUrl = process.env.REACT_APP_FLASK_API_URL;
        const response = await fetch(
          flaskApiUrl + `api/stockdata?page=${varPage}&rows=${rowsPerPage}&query=${queryParam}`
        );
        const jsonData = await response.json();

        setfilteredData(jsonData.data);
        setTotalCount(jsonData.count);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [page, rowsPerPage, queryParam]);

  // eslint-disable-next-line
  function setQueryP(queryText) {
    setQueryParam(queryText);
  }

  return (
    <AuthProvider>
      <div className="App">

        <ResponsiveAppBar />

        {/* <h1>StocksOwl</h1> */}

        <Routes>
          <Route
            path="/"
            element={
              <StockList
                stock={filteredData}
                loading={loading}
                handlePageChange={handlePageChange}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                totalCount={totalCount}
                queryParam={setQueryParam}
                setPage={setPage}
              />
            }
          />
          <Route
            path="stock/:id"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <LazyStockDetails />
              </Suspense> 
            }
          />
          <Route path="/marketindexes" element={<MarketIndexes />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/portfolio/:id/add-transaction" element={<PrivateRoute element={<AddTransaction />} />} />
          <Route path="/dashboard" element={<PrivateRoute element={<DashBoard />} />} />
          <Route path="/create-portfolio" element={<PrivateRoute element={<CreatePortfolio />} />} />
          <Route path="/portfolio/:id" element={<PrivateRoute element={<PortfolioDetails />} />} />
          <Route path="/portfolio/:portfolioId/transactions" element={<TransactionHistory />} />
          <Route path="/account" element={<PrivateRoute element={
            <Suspense fallback={<div>Loading...</div>}>
              <LazyAccount />
            </Suspense>
          } />} />
          {/* <Route path="/main-portfolio" element={<MainPortfolioList />} /> */}
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;
