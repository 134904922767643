import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import axiosInstance from './axiosInstance';
import useAxios from './axiosInstance';
import { AuthContext } from './AuthContext';

const CreatePortfolio = () => {
  const axiosInstance = useAxios();
  const [name, setName] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [transaction, setTransaction] = useState({
    stock_id: '', 
    transaction_type: 'buy', 
    quantity: '', 
    price: '', 
    timestamp: ''
  });
  const [stockSearch, setStockSearch] = useState('');
  const [stockOptions, setStockOptions] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);

  // Fetch stocks based on search query
  useEffect(() => {
    if (stockSearch.length > 2) {
      axiosInstance.get(`api/stocks`, { 
        params: { query: stockSearch }
      })
        .then(response => {
          setStockOptions(response.data);
        })
        .catch(error => {
          console.error("There was an error fetching stocks!", error);
        });
    } else {
      setStockOptions([]);
    }
  // eslint-disable-next-line
  }, [stockSearch, authState.token]);

  const handleAddTransaction = () => {
    if (selectedStock && transaction.quantity && transaction.price && transaction.timestamp) {
      setTransactions([...transactions, {
        ...transaction,
        stock_id: selectedStock.id, // Use selected stock ID
        name: selectedStock.name
      }]);
      setTransaction({
        stock_id: '',
        name: '', 
        transaction_type: 'buy', 
        quantity: '', 
        price: '', 
        timestamp: ''
      });
      setSelectedStock(null);
      setStockSearch('');
    }
  };

  const handleSubmit = () => {
    const newPortfolio = {
      name,
      user_id: 1,
      transactions
    };

    axiosInstance.post('api/main_portfolios', newPortfolio)
      .then(response => {
        navigate('/dashboard');
      })
      .catch(error => {
        console.error("There was an error creating the portfolio!", error);
      });
  };

  const handleBack = () => {
    navigate('/dashboard');  // Navigate back to the Dashboard page
  };

  return (
    <div className="relative bg-gray-100 min-h-screen">
      <div className="absolute top-4 left-4">
        <button
          onClick={handleBack}
          className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 transition"
        >
          Back to Dashboard
        </button>
      </div>
      <div className="flex items-center justify-center min-h-screen p-6">
        <div className="w-full max-w-3xl bg-white shadow-md rounded-lg">
          <h1 className="text-2xl font-bold text-center pt-6">Create Portfolio</h1>
          {/* Portfolio Name */}
          <div className="mb-4 px-6">
            <label className="block text-sm font-semibold mb-2">Portfolio Name</label>
            <input 
              type="text" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
              className="border border-gray-300 p-2 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter portfolio name"
            />
          </div>
          {/* Search and Select Stock */}
          <div className="mb-4 px-6">
            <label className="block text-sm font-semibold mb-2">Search Stock</label>
            <input 
              type="text" 
              value={stockSearch} 
              onChange={(e) => setStockSearch(e.target.value)} 
              className="border border-gray-300 p-2 w-full mb-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Search by stock name or symbol"
            />
            {stockOptions.length > 0 && (
              <ul className="border border-gray-300 rounded-lg bg-white mt-2">
                {stockOptions.map(stock => (
                  <li 
                    key={stock.id} 
                    onClick={() => { setSelectedStock(stock); setStockSearch(''); }} 
                    className="p-2 cursor-pointer hover:bg-gray-200"
                  >
                    {stock.name} ({stock.symbol})
                  </li>
                ))}
              </ul>
            )}
          </div>
          {/* Add Transaction Entry */}
          <div className="mb-4 px-6">
            <label className="block text-sm font-semibold mb-2">Add Transaction Entry</label>
            <input 
              type="text" 
              value={selectedStock ? `${selectedStock.name} (${selectedStock.symbol})` : 'Select Stock'} 
              readOnly
              className="border border-gray-300 p-2 w-full mb-2 rounded-lg bg-gray-100"
            />
            <select 
              value={transaction.transaction_type} 
              onChange={(e) => setTransaction({ ...transaction, transaction_type: e.target.value })} 
              className="border border-gray-300 p-2 w-full mb-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="buy">Buy</option>
              <option value="sell">Sell</option>
            </select>
            <input 
              type="number" 
              placeholder="Quantity" 
              value={transaction.quantity} 
              onChange={(e) => setTransaction({ ...transaction, quantity: e.target.value })} 
              className="border border-gray-300 p-2 w-full mb-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input 
              type="number" 
              placeholder="Price" 
              value={transaction.price} 
              onChange={(e) => setTransaction({ ...transaction, price: e.target.value })} 
              className="border border-gray-300 p-2 w-full mb-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input 
              type="datetime-local" 
              value={transaction.timestamp} 
              onChange={(e) => setTransaction({ ...transaction, timestamp: e.target.value })} 
              className="border border-gray-300 p-2 w-full mb-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button 
              onClick={handleAddTransaction} 
              className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-600 transition"
            >
              Add Transaction
            </button>
          </div>
          {/* Display Transaction Entries */}
          <div className="mb-4 px-6">
            <h2 className="text-lg font-semibold mb-2">Transaction Entries</h2>
            {transactions.length > 0 ? (
              <ul className="list-disc pl-5">
                {transactions.map((t, index) => (
                  <li key={index} className="mb-2">
                    <span className="font-medium">Stock:</span> {t.name}, 
                    <span className="font-medium"> Type:</span> {t.transaction_type}, 
                    <span className="font-medium"> Quantity:</span> {t.quantity}, 
                    <span className="font-medium"> Price:</span> {t.price}, 
                    <span className="font-medium"> Date:</span> {new Date(t.timestamp).toLocaleString()}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No transactions added yet.</p>
            )}
          </div>
          <div className="px-6 pb-6">
            <button 
              onClick={handleSubmit} 
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 transition"
            >
              Create Portfolio
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePortfolio;
