import React, { useState } from 'react';
import axios from 'axios';

function FileUpload() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileTags, setFileTags] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFileChange = (e) => {
    const files = e.target.files;
    setSelectedFiles([...files]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });
    if (selectedFiles.length) {
      try {
        const flaskApiUrl = process.env.REACT_APP_FLASK_API_URL;

        const response = await axios.post(flaskApiUrl + 'api/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        const results = response.data;
        var messageStr = "";
        for (const key in results) {
          messageStr += `${key}: ${results[key].message} \n`;
        }
        const tags = Object.keys(results).map((key) => (
          <div className="bg-gray-100 p-3 rounded-md shadow-md my-2" key={key}>
            <span className="block text-gray-700 font-medium">Filename: {key}</span>
            <span className="block text-gray-600">Message: {results[key].message}</span>
          </div>
        ));
        if ([201, 200].includes(response.status)) {
          alert(messageStr);
          window.location.reload();
        } else if (response.status === 409) {
          alert(messageStr);
        }
        setFileTags([]);
        setFileTags(tags);
        setIsModalOpen(false); // Close the modal on successful upload
        return tags;
      } catch (error) {
        console.error('File upload failed:', error);
      }
    }
  };

  return (
    <div>
      <button
        className="mt-4 py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        onClick={() => setIsModalOpen(true)}
      >
        Upload Files
      </button>

      {isModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div className="bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <label htmlFor="fileInput" className="block text-lg font-medium text-gray-700 mb-2">
                Upload your files here
              </label>
              <input
                type="file"
                id="fileInput"
                name="fileInput"
                onChange={handleFileChange}
                multiple
                className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-600 hover:file:bg-indigo-100"
              />
              <button
                className="mt-4 w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={handleUpload}
              >
                Upload
              </button>
              <button
                className="mt-4 w-full py-2 px-4 bg-gray-600 text-white font-semibold rounded-md shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
              <div className="mt-4">{fileTags}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FileUpload;
