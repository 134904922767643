import React, { useState, useEffect } from 'react';
import { Box, TextField, TablePagination, Button, Modal, ButtonGroup } from '@mui/material';
import './MarketIndexes.css';
import StockChartModal from './IndexChart';
import { CircularProgress } from '@mui/material';
import marketIndexes from './marketIndexes.json';


function MarketIndexes({ stock, page }) {
  const [orgStockIndex, setOrgStockIndex] = useState([]);
  const [stockIndex, setStockIndex] = useState([]);
  const [newPageChange, setPage] = React.useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [interval, setInterval] = useState("5m");
  const [activeTicker, setActiveTicker] = useState("");
  const [tickerName, setTickerName] = useState("");
  const [otherData, setOtherData] = useState({});
  const [activeChart, setActiveChart] = useState("5m");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // eslint-disable-next-line
  const [search, setSearch] = useState('');

  useEffect(() => {
    try {
      const flaskApiUrl = process.env.REACT_APP_FLASK_API_URL;

      fetch(flaskApiUrl + `api/indexdata`)
        .then((response) => response.json())
        .then((jsonData) => {
          setStockIndex(jsonData);
          setOrgStockIndex(jsonData);
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleSearch(e) {
    const searchTermInside = e.target.value;

    setPage(0);
    setSearch(searchTermInside);

    if (searchTermInside) {
      var filteredArray = orgStockIndex.filter((stock) => {
        return stock.index.toLowerCase().includes(searchTermInside.toLowerCase());
      });

      filteredDataCount = Object.keys(filteredArray).length;
      setStockIndex(filteredArray);
    } else {
      filteredDataCount = Object.keys(orgStockIndex).length;
      setStockIndex(orgStockIndex);
    }
  }

  var filteredDataList = [];
  const startIndex = rowsPerPage*newPageChange;

  if (Object.keys(stockIndex).length) {
    filteredDataList = Object.keys(stockIndex).slice(
      newPageChange*rowsPerPage, (newPageChange+1)*rowsPerPage
    );
  } else {
    filteredDataList = stockIndex;
  }

  var filteredDataCount = Object.keys(stockIndex).length;

  const handleOpen = (ticker) => {
    setModalOpen(true);
    setTickerName(ticker);
    setActiveTicker(marketIndexes[ticker]);
  };

  const handleClose = () => {
    setActiveTicker("");
    setTickerName("");
    setOtherData({});
    setModalOpen(false);
  };

  const handleIntervalClick = (selectedInterval) => {
    setInterval(selectedInterval);
    setActiveChart(selectedInterval);
  };

  const handleChartClick = (selectedInterval) => {
    return activeChart === selectedInterval ? "contained" : "outlined"
  };

  return (
    <div className="stock-list-container container mt-4">
      <Box sx={{ m: 2, width: '97%' }}>
        <TextField
          className="search-bar"
          variant="outlined"
          label="Search..."
          fullWidth
          onChange={handleSearch}
        />
      </Box>
      <h1 className="stock-list-title text-center mb-4">Market Indices</h1>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {loading ? ( // Render loading spinner when loading is true
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 9999, // Ensure CircularProgress is stacked on top
                color: 'black',
                strokeWidth: '10px',
              }}
              size={45}
              thickness={9}
            />
          </div>
        ) : (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '92%',
              height: '97%',
              padding: 15,
              borderRadius: 8,
              backgroundColor: 'rgba(0, 0, 0, 0.5)', /* semi-transparent black */
              backdropFilter: 'blur(8px)',
              zIndex: 999,
            }}
          >
          <div>
            <h2 style={{
                textAlign: 'center',
                color: 'white'
            }}>
              {tickerName}
            </h2>
            <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <div style={{ marginRight: '20px' }}>
                <h3 style={{ textAlign: 'center', color: 'white' }}>
                  Current Price
                </h3>
                <h4 style={{ textAlign: 'center', color: 'white' }}>
                  {otherData['currentPrice']}
                </h4>
              </div>
              <div style={{ marginRight: '20px' }}>
                <h3 style={{ textAlign: 'center', color: 'white' }}>
                  Day High
                </h3>
                <h4 style={{ textAlign: 'center', color: 'white' }}>
                  {otherData['dayHigh']}
                </h4>
              </div>
              <div style={{ marginRight: '20px' }}>
                <h3 style={{ textAlign: 'center', color: 'white' }}>
                  Day Low
                </h3>
                <h4 style={{ textAlign: 'center', color: 'white' }}>
                  {otherData['dayLow']}
                </h4>
              </div>
              <div style={{ marginRight: '20px' }}>
                <h3 style={{ textAlign: 'center', color: 'white' }}>
                  Average Volume
                </h3>
                <h4 style={{ textAlign: 'center', color: 'white' }}>
                  {otherData['averageVolume']}
                </h4>
              </div>
              <div style={{ marginRight: '20px' }}>
                <h3 style={{ textAlign: 'center', color: 'white' }}>
                  Average 10d Volume
                </h3>
                <h4 style={{ textAlign: 'center', color: 'white' }}>
                  {otherData['average10dVolume']}
                </h4>
              </div>
            </div>
            <p style={{
              textAlign: 'center',
              color: 'white'
            }}>
              Guidelines:
              <br />
              Pan: Use Pan to adjust the chart.
              <br />
              Zoom: There would be a plus sign as a cursor on chart, you can scroll in and out.
              <br />
            </p>

            <ButtonGroup variant="outlined" aria-label="Loading button group">
              <Button variant={ handleChartClick('1d') } size="small" color="success" onClick={() => handleIntervalClick('1d')}>
                1d
              </Button>
              <Button variant={ handleChartClick('1mo') } size="small" color="success" onClick={() => handleIntervalClick('1mo')}>
                1mo
              </Button>
              <Button variant={ handleChartClick('3mo') } size="small" color="success" onClick={() => handleIntervalClick('3mo')}>
                3mo
              </Button>
              <Button variant={ handleChartClick('1m') } size="small" color="success" onClick={() => handleIntervalClick('1m')}>
                1m
              </Button>
              <Button variant={ handleChartClick('5m') } size="small" color="success" onClick={() => handleIntervalClick('5m')}>
                5m
              </Button>
              <Button variant={ handleChartClick('1h') } size="small" color="success" onClick={() => handleIntervalClick('1h')}>
                1h
              </Button>
            </ButtonGroup>
          </div>
          <div id="myDiv">
          </div>
        </div>
      )}
      </Modal>

      <StockChartModal
        open={modalOpen}
        ticker={activeTicker}
        setLoading={setLoading}
        interval={interval}
        otherData={setOtherData}
      />

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredDataCount}
        rowsPerPage={rowsPerPage}
        page={newPageChange}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {Object.keys(filteredDataList).length === 0 ? (
        <div className="text-center">
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-borderless mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Index</th>
                <th>Current Price</th>
                <th>Percent Change</th>
                <th>Open</th>
                <th>High</th>
                <th>Low</th>
                <th>Previous Close</th>
                {/* <th>P/B Ratio</th>
                <th>P/E Ratio</th> */}
                <th>Year High</th>
                <th>Year Low</th>
              </tr>
            </thead>
            <tbody>
              {filteredDataList.map((stockIter, index) => (
                <tr key={startIndex + index + 1}>
                    {/* dividend_yield_ratio, high, id, index, low, open,
                    previous_close, price_to_book_ratio, price_to_earning_ratio,
                    updated_date, year_high, year_low */}
                  <td>{startIndex + index + 1}</td>
                  <td>{stockIndex[stockIter].index}</td>
                  <td className="priceRight">{stockIndex[stockIter].current_price.toFixed(2)}</td>
                  <td className="priceRight">{stockIndex[stockIter].percent_change} %</td>
                  <td className="priceRight">{stockIndex[stockIter].open.toFixed(2)}</td>
                  <td className="priceRight">{stockIndex[stockIter].high.toFixed(2)}</td>
                  <td className="priceRight">{stockIndex[stockIter].low.toFixed(2)}</td>
                  <td className="priceRight">{stockIndex[stockIter].previous_close.toFixed(2)}</td>
                  {/* <td className="priceRight">{stockIndex[stockIter].price_to_book_ratio.toFixed(2)}</td>
                  <td className="priceRight">{stockIndex[stockIter].price_to_earning_ratio.toFixed(2)}</td> */}
                  <td className="priceRight">{stockIndex[stockIter].year_high.toFixed(2)}</td>
                  <td className="priceRight">{stockIndex[stockIter].year_low.toFixed(2)}</td>
                  <td>
                  <button onClick={
                      () => handleOpen(
                        stockIndex[stockIter].index
                      )
                    } style={{
                      height: 'auto',
                    }}
                    className="btn btn-outline-dark btn-sm"
                  >
                    Open Chart
                  </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <br/>
      {filteredDataCount ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredDataCount}
          rowsPerPage={rowsPerPage}
          page={newPageChange}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <div />
      )}
    </div>
  );
}

export default MarketIndexes;
