// PrivateRoute.js
import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Adjust path as needed

const PrivateRoute = ({ element: Component }) => {
  const { authState } = useAuth();
  const location = useLocation();

  return authState.isLoggedIn ? (
    Component
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
