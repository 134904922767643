import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from './firebaseConfig'; // Ensure your firebaseConfig exports auth

const Label = ({ htmlFor, children }) => (
  <label htmlFor={htmlFor} className="block text-sm font-medium text-gray-700">
    {children}
  </label>
);

const Input = ({ id, type, placeholder, className, value, onChange }) => (
  <input
    id={id}
    type={type}
    placeholder={placeholder}
    className={`block w-full px-3 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${className}`}
    value={value}
    onChange={onChange}
  />
);

const Button = ({ type, className, children, disabled, onClick }) => (
  <button
    type={type}
    className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${className}`}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
);

const errorMessages = {
  'auth/email-already-in-use': 'The email address is already in use by another account.',
  'auth/invalid-email': 'The email address is not valid.',
  'auth/operation-not-allowed': 'Email/password accounts are not enabled.',
  'auth/weak-password': 'The password is too weak.',
  'auth/network-request-failed': 'Network error. Please try again.',
  // Add more custom error messages as needed
};

const getErrorMessage = (errorCode) => {
  return errorMessages[errorCode] || 'An error occurred. Please try again.';
};

const SignUp = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const navigate = useNavigate();

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    setLoading(true);
    setError('');

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const user_uid = user.uid;
      await signOut(auth);


      const flaskApiUrl = process.env.REACT_APP_FLASK_API_URL;
      const response = await fetch(`${flaskApiUrl}/api/signup`, {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, username, firebase_uuid: user_uid, password }),

      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.success) {
        console.log('Signup successful');
        setShowModal(true);
        navigate('/login');
      } else {
        setError(data.message || 'Signup failed');
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error.code);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleSignInClick = () => navigate('/login');

  const closeModal = () => {
    setShowModal(false);
    navigate('/login');
  };

  return (
    <div className="flex h-screen w-full items-center justify-center bg-gradient-to-br from-[#f5f5f5] to-[#e0e0e0]">
      <div className="w-full max-w-md rounded-lg bg-white p-6 shadow-lg transition-all duration-300 hover:shadow-xl">
        <div className="mb-6 text-center">
          <h1 className="text-3xl font-bold">Create an account</h1>
          <p className="text-muted-foreground">Sign up with your email and password</p>
        </div>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <Label htmlFor="username">Username</Label>
            <Input
              id="username"
              type="text"
              value={username}
              onChange={handleUsernameChange}
              placeholder="Your username"
              className="mt-1"
              required
            />
          </div>
          <div>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="example@email.com"
              className="mt-1"
              required
            />
          </div>
          <div>
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="••••••••"
              className="mt-1"
              required
            />
          </div>
          <div>
            <Label htmlFor="confirmPassword">Confirm Password</Label>
            <Input
              id="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              placeholder="••••••••"
              className="mt-1"
              required
            />
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <Button type="submit" className="w-full" disabled={loading}>
            {loading ? 'Signing up...' : 'Sign up'}
          </Button>
          <Button type="button" className="w-full" onClick={handleSignInClick}>
            Sign in
          </Button>
        </form>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <h2 className="text-xl font-bold">Signup Successful</h2>
            <p className="mt-2">You have successfully signed up. Please proceed to the login page.</p>
            <div className="mt-4 flex justify-center">
              <Button type="button" onClick={closeModal}>
                Proceed to Login
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUp;
