import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { Box, TextField, TablePagination } from '@mui/material';
import FileUpload from './FileUpload';

function StockList({ stock, loading, handlePageChange, handleChangeRowsPerPage, page, rowsPerPage, totalCount, queryParam, setPage }) {
  // eslint-disable-next-line
  const [filteredData, setfilteredData] = useState({});
  // eslint-disable-next-line
  const [search, setSearch] = useState('');
  const startIndex = page * rowsPerPage;
  // eslint-disable-next-line
  var filteredDataCount = Object.keys(stock).length;

  var filteredDataList = [];

  if (Object.keys(stock).length) {
    filteredDataList = Object.keys(stock);
  } else if (
    Object.keys(stock).length
   ) {
    filteredDataList = Object.keys(stock);
  } else {
    filteredDataList = {};
  }

  // eslint-disable-next-line
  function handleSearch(e) {
    const searchTermInside = e.target.value;
    handlePageChange(e, 0);
    setSearch(searchTermInside);

    if (searchTermInside) {
      const filteredArray = Object.keys(stock).filter(
        (stock) => stock.toLowerCase().includes(searchTermInside.toLowerCase())
      );

      const filteredDict = {};
      filteredArray.forEach((item) => {
        filteredDict[item] = stock[item];
      });

      setfilteredData(filteredDict);
    } else {
      // filteredDataCount = Object.keys(stock).length;
      setfilteredData(stock);
    }

  }

  function setQuery(e) {
    queryParam(e.target.value);
    setPage(0);
  }



  return (
    <div className="stock-list-container container mt-4">
      <FileUpload />
      <Box sx={{ m: 2, width: '97%' }}>
        <TextField
          className="search-bar"
          variant="outlined"
          label="Search..."
          fullWidth
          onChange={setQuery}
        />
      </Box>
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9998,
            backdropFilter: 'blur(2px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999,
              color: 'black',
              strokeWidth: '10px',
            }}
            size={45}
            thickness={9}
          />
        </div>
      )}
      <h1 className="stock-list-title text-center mb-4">Stock List</h1>
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[50, 100, 200]}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {Object.keys(filteredDataList).length === 0 ? (
        <div className="text-center">
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-borderless mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Stock Name</th>
                <th>Symbol</th>
                <th>Face Value</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredDataList.map((stockIter, index) => (
                <tr key={startIndex + index + 1}>
                  <td>{startIndex + index + 1}</td>
                  <td>{stockIter}</td>
                  <td>{stock[stockIter].symbol}</td>
                  <td>{stock[stockIter].face_value}</td>
                  <td>
                    <Link to={`/stock/${stock[stockIter].id}`} className="btn btn-outline-dark btn-sm">
                      View Details
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <br/>
      {totalCount ? (
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[50, 100, 200]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <div />
      )
      }
    </div>
  );
}

export default StockList;
