import React, { useEffect } from 'react';
import Plotly from 'plotly.js-dist';

const StockChartModal = ({ open, close, ticker, setLoading, interval, otherData }) => {

  // async function fetchWithTimeout(resource, options = {}) {
  //   const { timeout = 15000 } = options;
    
  //   const controller = new AbortController();
  //   const id = setTimeout(() => controller.abort(), timeout);
  
  //   const response = await fetch(resource, {
  //     ...options,
  //     signal: controller.signal  
  //   });
  //   clearTimeout(id);

  //   return response;
  // }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const flaskApiUrl = process.env.REACT_APP_FLASK_API_URL;
        const response = await fetch(`${flaskApiUrl}/historical-data/${ticker}?interval=${interval}`);

        setLoading(false);

        if (!response.ok) {
          setLoading(false);
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        const jsonResp = jsonData.data;
        otherData({
          "currentPrice": jsonData.currentPrice,
          "average10dVolume": jsonData.average10dVolume,
          "averageVolume": jsonData.averageVolume,
          "dayHigh": jsonData.dayHigh,
          "dayLow": jsonData.dayLow,

          // "change": jsonData.change,
          // "changePercent": jsonData.changePercent,
          // "volume": jsonData.volume,
          // "marketCap": jsonData.marketCap,
          // "peRatio": jsonData.peRatio,
          // "dividendYield": jsonData.dividendYield,
          // "beta": jsonData.beta,
          // "52WeekHigh": jsonData.52WeekHigh,
          // "52WeekLow": jsonData.52WeekLow,
          // "open": jsonData.open,
          // "high": jsonData.high,
          // "low": jsonData.low,
          // "close": jsonData.close,
          // "previousClose": jsonData.previousClose,
          // "changeFromPreviousClose": jsonData.changeFromPreviousClose,
          // "changePercentFromPreviousClose": jsonData.changePercentFromPreviousClose,
          // "changeFromPreviousClosePercent": jsonData.changeFromPreviousClosePercent,
          // "changeFromPreviousClosePercent": jsonData.changeFromPreviousClosePercent,
          // "changeFromPreviousClosePercent": jsonData.changeFromPreviousClosePercent,
        })

        const dates = Object.keys(jsonResp);
        const values = Object.values(jsonResp).map(entry => entry.Close);
        const rangebreaks = [
          // Make bounds such that when it is monthly the bounds are based on monthly
          // dates only
          { values: values }, // Example of breaking at a specific date

        ];

        if ( interval === "1m" ) {
          const startYear = 2007;
          // Create a new Date object
          const currentDate = new Date();

          // Get the current year
          const endYear = currentDate.getFullYear();

          // Loop through each year
          for (let year = startYear; year <= endYear; year++) {
            // Loop through each month
            for (let month = 1; month <= 12; month++) {
              // Get the number of days in the month
              const daysInMonth = new Date(year, month, 0).getDate();
              const bounds = [
                `${year}-${month.toString().padStart(2, '0')}-10`, // Start of the month
                `${year}-${month.toString().padStart(2, '0')}-${daysInMonth.toString().padStart(2, '0')}`, // End of the month
              ];

              // Push bounds to rangebreaks list
              rangebreaks.push({ bounds: bounds, pattern: 'month' });
            }
          }
        }

        if (interval === "1m" | interval === "5m" | interval === "1h") {
          rangebreaks.push({ bounds: ['sat', 'mon'] });
          rangebreaks.push({ bounds: [15.5, 9.25], pattern: 'hour' }); // Example of breaking between two hours (5pm and 9am)
          rangebreaks.push({ bounds: [dates[0], dates[-1]] }); // Example of breaking between two dates
          rangebreaks.push({ bounds: [15.5, 9.25], pattern: 'hour' });
        }

        const layout = {
          // width: 1150, // Set the width of the plot
          // height: 650,
          // width: 1250,
          // height: '70%',
          align: 'center',
          width: window.innerWidth * 0.90,
          height: window.innerHeight * 0.65,
          xaxis: {
            title: ticker + ' Time Series',
            autorange: true,
            range: [dates[0], dates[-1]],
            gridcolor: 'rgb(200, 200, 200)',
            rangeselector: {buttons: [
                {
                  count: 1,
                  label: '1m',
                  step: 'month',
                  stepmode: 'backward'
                },
                {
                  count: 6,
                  label: '6m',
                  step: 'month',
                  stepmode: 'backward'
                },
                {step: 'all'}
            ]},
            // rangeslider: {range: [dates[0], dates[-1]]},
            rangebreaks: rangebreaks,
          },
          yaxis: {
            autorange: true,
            gridcolor: 'rgb(200, 200, 200)',
            tickformat: 'digits'
          },
          showlegend: false
        };

        var newData = [
          {
            x: dates,
            y: values,
            type: 'scatter',
            text: Object.values(jsonData).map(
              entry => `Open: ${entry.Open}<br>High: ${entry.High}<br>Low: ${entry.Low}`
            ),
            mode: 'lines',
            line: {
              shape: 'spline',
              smoothing: 1.5,
            },
          }
        ];

        if (open) {
          setLoading(false);
          Plotly.newPlot('myDiv', newData, layout, {scrollZoom: true});
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (open) {
      fetchData();
    }
    // Fetch data only when modal is opened
    // eslint-disable-next-line
  }, [open, ticker, interval]);

  return (
    <div>
    </div>
  );
};

export default StockChartModal;
