import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxios from './axiosInstance';
import { AuthContext } from './AuthContext';
import { CircularProgress } from '@mui/material';

const Dashboard = () => {
  const [portfolios, setPortfolios] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [newName, setNewName] = useState('');
  const [loading, setLoading] = useState(false);
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();
  const axiosInstance = useAxios();

  useEffect(() => {
    const fetchDashboard = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get('api/main_portfolios');
        setPortfolios(response.data);
      } catch (error) {
        console.error('There was an error fetching the portfolios!', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboard();
    // eslint-disable-next-line
  }, [authState.token]);

  const handleCreatePortfolio = () => {
    navigate('/create-portfolio');  // Navigate to the create portfolio page
  };

  const handleEditName = (id, currentName) => {
    setEditingId(id);
    setNewName(currentName);  // Prefill the input field with the current name
  };

  const handleSaveName = (id) => {
    axiosInstance.put(`api/main_portfolios/${id}`, { name: newName })
    .then(response => {
      const updatedPortfolios = portfolios.map(portfolio =>
        portfolio.id === id ? { ...portfolio, name: response.data.name } : portfolio
      );
      setPortfolios(updatedPortfolios);
      setEditingId(null);
    })
    .catch(error => {
      console.error('There was an error updating the portfolio name!', error);
    });
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setNewName('');
  };

  const handleCardClick = (id) => {
    navigate(`/portfolio/${id}`);
  };

  const handleBack = () => {
    navigate('/');  // Navigate back to home page
  };

  return (
    <div className="p-4">
      <div className="flex justify-between mb-4">
        <button
          onClick={handleBack}
          className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 transition"
        >
          Back to Home
        </button>
        <button
          onClick={handleCreatePortfolio}
          className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-600 transition"
        >
          Create New Portfolio
        </button>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {portfolios.map(portfolio => {
          const currentValue = parseFloat(portfolio.total_current_value).toFixed(2);
          const investedValue = parseFloat(portfolio.total_invested_value).toFixed(2);
          const profitLoss = (currentValue - investedValue).toFixed(2);
          const profitLossPercentage = ((profitLoss / investedValue) * 100).toFixed(2);
          const isProfit = profitLoss >= 0;

          return (
            <div
              key={portfolio.id}
              className="bg-white p-4 border border-gray-200 rounded-lg shadow cursor-pointer hover:bg-gray-100 transition"
              onClick={() => handleCardClick(portfolio.id)}
            >
              {editingId === portfolio.id ? (
                <div>
                  <input
                    type="text"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    className="border border-gray-300 rounded-lg px-2 py-1 mb-2 w-full"
                    onClick={(e) => e.stopPropagation()}  // Prevent navigation on input click
                  />
                  <div className="flex space-x-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSaveName(portfolio.id);
                      }}
                      className="bg-green-500 text-white py-1 px-3 rounded-lg shadow hover:bg-green-600 transition"
                    >
                      Save
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCancelEdit();
                      }}
                      className="bg-red-500 text-white py-1 px-3 rounded-lg shadow hover:bg-red-600 transition"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <h3 className="text-lg font-semibold mb-2">{portfolio.name}</h3>
                  <p className="text-sm text-gray-600 mb-2">
                    Total Invested Value: ₹{investedValue}
                  </p>
                  <p className="text-sm text-gray-600 mb-2">
                    Current Value: ₹{currentValue}
                  </p>
                  <p
                    className={`text-sm font-semibold mb-2 ${
                      isProfit ? 'text-green-500' : 'text-red-500'
                    }`}
                  >
                    P/L: ₹{profitLoss} ({profitLossPercentage}%)
                  </p>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditName(portfolio.id, portfolio.name);
                    }}
                    className="bg-yellow-500 text-white py-1 px-3 rounded-lg shadow hover:bg-yellow-600 transition"
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9998,
            backdropFilter: 'blur(2px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress
            style={{
              color: 'black',
              strokeWidth: '10px',
            }}
            size={45}
            thickness={9}
          />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
