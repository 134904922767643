import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from './axiosInstance';
import {
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AuthContext } from './AuthContext';
import { format, parseISO } from 'date-fns';

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const { authState } = useContext(AuthContext);
  const axiosInstance = useAxios();
  const { portfolioId } = useParams();

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`/api/main_portfolios/${portfolioId}/transactions`);
        setTransactions(response.data);
        setFilteredTransactions(response.data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
    // eslint-disable-next-line
  }, [authState.token, portfolioId]);

  const handleFilterChange = () => {
    const filtered = transactions.filter((transaction) => {
      const date = new Date(transaction.timestamp);
      return (
        (selectedYear ? date.getFullYear() === parseInt(selectedYear) : true) &&
        (selectedMonth ? date.getMonth() === parseInt(selectedMonth) : true)
      );
    });
    setFilteredTransactions(filtered);
  };

  const groupedTransactions = filteredTransactions.reduce((acc, transaction) => {
    const date = parseISO(transaction.timestamp);
    const month = format(date, 'MMMM yyyy');
    if (!acc[month]) acc[month] = [];
    acc[month].push(transaction);
    return acc;
  }, {});

  const totalTransactions = (transactions) => {
    let totalBuy = 0;
    let totalSell = 0;
    transactions.forEach((transaction) => {
      if (transaction.transaction_type === 'buy') {
        totalBuy += transaction.quantity * transaction.price;
      } else if (transaction.transaction_type === 'sell') {
        totalSell += transaction.quantity * transaction.price;
      }
    });
    return { totalBuy, totalSell };
  };

  const { totalBuy: totalBuyAll, totalSell: totalSellAll } = totalTransactions(filteredTransactions);

  return (
    <div className="p-4">
      <Typography variant="h4" gutterBottom>Transaction History</Typography>
      <div className="mb-4 flex space-x-4">
      <Grid container spacing={2} alignItems="center">
  <Grid item xs={12} sm={4} md={3}>
    <FormControl fullWidth>
      <InputLabel>Year</InputLabel>
      <Select
        value={selectedYear}
        onChange={(e) => setSelectedYear(e.target.value)}
        label="Year"
      >
        <MenuItem value=""><em>All</em></MenuItem>
        {[...new Set(transactions.map(transaction => new Date(transaction.timestamp).getFullYear()))].map(year => (
          <MenuItem key={year} value={year}>{year}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </Grid>
  <Grid item xs={12} sm={4} md={3}>
    <FormControl fullWidth>
      <InputLabel>Month</InputLabel>
      <Select
        value={selectedMonth}
        onChange={(e) => setSelectedMonth(e.target.value)}
        label="Month"
      >
        <MenuItem value=""><em>All</em></MenuItem>
        {Array.from({ length: 12 }, (v, k) => k).map(month => (
          <MenuItem key={month} value={month}>{format(new Date(2024, month), 'MMMM')}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </Grid>
  <Grid item xs={12} sm={4} md={3}>
    <Button
      variant="contained"
      color="primary"
      onClick={handleFilterChange}
      fullWidth
    >
      Filter
    </Button>
  </Grid>
</Grid>
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <CircularProgress />
        </div>
      ) : (
        <div>
          {Object.entries(groupedTransactions).map(([month, transactions]) => {
            const { totalBuy, totalSell } = totalTransactions(transactions);
            return (
              <Accordion key={month}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${month}-content`}
                    id={`${month}-header`}
                >
                    <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                        <Typography>{month}</Typography>
                        <Box display="flex" alignItems="center">
                        <Typography component="span" style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <span style={{ minWidth: '40px', textAlign: 'right' }}>Buy:</span>
                            <span style={{ minWidth: '150px', textAlign: 'right', paddingLeft: '10px', paddingRight: '10px' }}>₹{totalBuy.toFixed(2)}</span>
                        </Typography>
                        <Typography component="span" style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <span style={{ minWidth: '40px', textAlign: 'right' }}>Sell:</span>
                            <span style={{ minWidth: '150px', textAlign: 'right', paddingLeft: '10px' }}>₹{totalSell.toFixed(2)}</span>
                        </Typography>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Stock Symbol</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell align="right" style={{ minWidth: '100px' }}>Buy</TableCell>
                                <TableCell align="right" style={{ minWidth: '100px' }}>Sell</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {transactions.map((transaction) => (
                                <TableRow key={transaction.id}>
                                <TableCell>{format(parseISO(transaction.timestamp), 'dd MMM yyyy')}</TableCell>
                                <TableCell>{transaction.stock_symbol}</TableCell>
                                <TableCell>{transaction.quantity}</TableCell>
                                <TableCell>₹{parseFloat(transaction.price).toFixed(2)}</TableCell>
                                {transaction.transaction_type === 'buy' ? (
                                    <>
                                    <TableCell align="right" style={{ minWidth: '100px' }}>₹{parseFloat(transaction.price).toFixed(2)}</TableCell>
                                    <TableCell align="right" style={{ minWidth: '100px' }}></TableCell>
                                    </>
                                ) : (
                                    <>
                                    <TableCell align="right" style={{ minWidth: '100px' }}></TableCell>
                                    <TableCell align="right" style={{ minWidth: '100px' }}>₹{parseFloat(transaction.price).toFixed(2)}</TableCell>
                                    </>
                                )}
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
              </Accordion>
            );
          })}
          <div className="mt-4 p-4 bg-gray-100 border border-gray-200 rounded-lg">
            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                <Typography>Total Summary</Typography>
                <Box display="flex" alignItems="center">
                <Typography component="span" style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <span style={{ minWidth: '50px', textAlign: 'right' }}>Buy:</span>
                    <span style={{ minWidth: '148px', textAlign: 'right', paddingLeft: '10px', paddingRight: '10px' }}>₹{totalBuyAll.toFixed(2)}</span>
                </Typography>
                <Typography component="span" style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <span style={{ minWidth: '55px', textAlign: 'right' }}>Sell:  </span>
                    <span style={{ minWidth: '155px', textAlign: 'right', paddingLeft: '10px' }}>₹{totalSellAll.toFixed(2)}</span>
                </Typography>
                </Box>
            </Box>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionHistory;
