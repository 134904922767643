import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import axiosInstance from './axiosInstance';
import useAxios from './axiosInstance';
import { AuthContext } from './AuthContext';
import CircularProgress from '@mui/material/CircularProgress';

const PortfolioDetails = () => {
  const { id } = useParams();
  const [portfolio, setPortfolio] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const axiosInstance = useAxios();

  useEffect(() => {
    const fetchPortfolio = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
            `api/main_portfolios/${id}`
        );
        setPortfolio(response.data);
      } catch (error) {
        setError('Failed to fetch portfolio data.');
        console.error("There was an error fetching the portfolio!", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPortfolio();
    // eslint-disable-next-line
  }, [id, authState.token]);

  const handleAddTransaction = () => {
    navigate(`/portfolio/${id}/add-transaction`);
  };

  const handleBackToDashboard = () => {
    navigate('/dashboard'); // Navigate to the home page
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!portfolio) return <div>No portfolio data available.</div>;

  return (
    <div className="p-6 relative">
      {/* Flex container for buttons */}
      <div className="flex justify-between items-center mb-6">
        <button 
          onClick={handleBackToDashboard} 
          className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 transition"
        >
          Back to Dashboard
        </button>
        <button
          onClick={() => navigate(`/portfolio/${id}/transactions`)}
          className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-600 transition"
        >
          View Transaction History
        </button>
        <button 
          onClick={handleAddTransaction} 
          className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-600 transition"
        >
          Add Transaction
        </button>
      </div>
      <h1 className="text-3xl font-bold mb-6">{portfolio.name}</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {portfolio.stocks.map((stock, index) => (
          <div key={index} className="bg-white border rounded-lg p-4 shadow-md">
            <div className="flex flex-col h-full">
              <div className="flex flex-col mb-4">
                <span className="text-xl font-semibold">{stock.stock.name}</span>
                <span className="text-gray-600">Invested Value: ₹{parseFloat((stock.price * stock.quantity).toFixed(2))}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span className="font-semibold">Total Quantity:</span>
                <span>{stock.quantity}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span className="font-semibold">Average Price:</span>
                <span>₹{stock.price.toFixed(2)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9998,
            backdropFilter: 'blur(2px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress
            style={{
              color: 'black',
              strokeWidth: '10px',
            }}
            size={45}
            thickness={9}
          />
        </div>
      )}
    </div>
  );
};

export default PortfolioDetails;
